const module3Messages = {
  part1: {
    intro: {
      objectif1: 'Identifier les acteurs impliqués dans le dispositif MaPrimeRénov’, leurs rôles et leurs interactions'
    },
    lesson: {
      title: 'Les acteurs du dispositif MaPrimeRénov’',
      titleAnnotation: 'Pour accompagner une démarche MaPrimeRénov’, il est important de bien identifier les différents acteurs qui entrent en scène, connaître leurs rôles et comprendre leurs interactions.',
      block1: {
        title: 'Les 6 familles d’acteurs',
        groups: {
          group1: {
            title: 'Les financeurs',
            actors: {
              actor1: {
                name: 'L’État',
                content: 'finance le dispositif'
              },
              actor2: {
                name: 'L’ANAH',
                content: 'instruit les demandes et délivre les primes'
              }
            }
          },
          group2: {
            title: 'Les bénéficiaires / maîtres d\'ouvrage',
            actors: {
              actor1: {
                content: '<b>Les propriétaires</b> ou usufruitiers <b>occupants leur logement</b>'
              },
              actor2: {
                content: '<b>Les propriétaires bailleurs</b> louant leur bien comme <b>résidence principale</b>'
              },
              actor3: {
                content: '<b>Les copropriétés,</b> représentées par leur syndic. '
              }
            }
          },
          group3: {
            title: 'Les pros du bâtiment',
            content: 'Ce sont les <b>artisans ou entreprises du bâtiment disposant du label RGE</b> qui réalisent les chantiers éligibles à MaPrimeRénov’. <b>Ils sont les garants de la qualité des travaux.</b>'
          },
          group4: {
            title: 'Les conseillers',
            name: 'Les membres du réseau France Rénov’',
            content: '<b>Leur mission de service public est d’informer tous les bénéficiaires</b> et de répondre à leurs questions <b>concernant la rénovation énergétique de leur logement ou de leur copropriété.</b>'
          },
          group5: {
            title: 'Les accompagnateurs',
            actors: {
              actor1: {
                name: 'Les AMO ou Assistants à Maîtrise d’ouvrage',
                content: 'Les AMO ont un rôle similaire à l\'Accompagnateur Rénov\' mais leur <b>mission se concentre sur la partie technique et financière.</b><br><br>' +
                  'Il peut s’agir d’architectes, de bureaux d’études, d’artisans et d’entreprises du bâtiment à condition qu’ils ne réalisent pas les travaux.<br><br>' +
                  'Le recours à un Accompagnateur Rénov\' est <b>obligatoire pour les demandes MaPrimeRénov\' Copropriété et financé à hauteur de 50% du montant de la prestation.</b>'
              },
              actor2: {
                name: 'Les Accompagnateurs Rénov’',
                content: 'Leur rôle est d\'<b>accompagner les ménages dans leur parcours de rénovation</b> de la définition des travaux à leur réception <b>en apportant un appui technique, administratif, financier et social.</b><br><br>' +
                  '<b>Toute personne</b> physique ou morale <b>qualifiée d\'auditeur énergétique et agréée par l\'ANAH peut être Accompagnateur Rénov\'</b> à l\'exception des entreprises du bâtiment et des diagnostiqueurs immobiliers.',
                content2: 'Le recours à un Accompagnateur Rénov\' est obligatoire pour les demandes d\'aide MaPrimeRénov\' "Parcours Accompagné".<br><br>' +
                  'Le ménage peut choisir son Accompagnateur Rénov\'. La prestation est subventionnée par MaPrimeRénov\' jusqu\'à 2&nbsp;000€.'
              }
            }
          },
          group6: {
            title: 'Les mandataires',
            content: 'Ce sont des <b>tiers de confiance désignés par les bénéficiaires / maîtres d\'ouvrage</b> eux-mêmes pour <b>gérer leur dossier.</b> Il peut s’agir de professionnels du bâtiment, qu’ils réalisent ou non les travaux, de conseillers FranceRénov’ ou encore de proches du bénéficiaire.',
            content2: 'Il existe 3 types de mandats&nbsp;:<br>' +
              '- <b>administratif,</b> pour le suivi du dossier<br>' +
              '- <b>financier,</b> pour percevoir la prime en lieu et place du bénéficiaire<br>' +
              '- <b>mixte,</b> cumulant les mandats (administratif et financier).'
          }
        }
      },
      block2: {
        title: 'Les interactions entre les acteurs',
        videoButton: 'Découvrir les interactions en vidéo'
      }
    }
  },
  part2: {
    intro: {
      objectif1: 'Identifier les types d’aides MaPrimeRénov’',
      objectif2: 'Connaître les règles de calcul du montant des aides',
      objectif3: 'Connaître les spécificités de MaPrimeRénov’ Copropriétés'
    },
    lesson1: {
      tab: 'éligibilité',
      title: 'Les aides MaPrimeRénov’',
      subTitle: 'pour les logements individuels',
      intro: 'Le dispositif MaPrimeRénov’ propose 2 types d’aides à la rénovation énergétique des logements individuels.',
      intro2: '<br><b>L\'éligibilité à l\'un ou l\'autre type d\'aide dépend&nbsp;:</b><br>' +
        '- <b>de la classe énergétique</b> du logement<br>' +
        '- <b>du niveau de revenus</b> du ménage.',
      mprMenages: {
        aise: 'Supérieurs',
        inter: 'Intermédiaires',
        modeste: 'Modestes',
        tresModeste: 'Très modestes'
      },
      logementLabel: 'logements concernés',
      menageLabel: 'ménages concernés',
      montantLabel: 'montant de l’aide',
      conditionLabel: 'conditions',
      block1: {
        title: 'Le parcours accompagné',
        intro: 'Cette aide finance les <b>rénovations performantes c’est-à-dire permettant de gagner à minima 2 classes énergétiques.</b> Elle est donc réservée aux logements les plus énergivores.',
        conditions: {
          eval: 'Isolation de 2 parois (menuiseries comprises) et traitement de la ventilation, si nécessaire',
          accomp: 'Avoir recours à Mon Accompagnateur Rénov’',
          sautClasse: 'Saut de 2 classes énergétiques ou plus'
        },
        montantInfo: 'Le montant de l’aide est calculé en <b>pourcentage du coût HT des travaux.',
        montantNiveau: 'Ce pourcentage <b>dépend du nombre de sauts de classe et des revenus</b> du ménage et est <b>majoré de 10 % en cas de sortie de passoire.</b>',
        montantPlafond: '<b>Le montant des dépenses éligibles est plafonné</b> selon le nombre de sauts de classe (de 40 000 € HT pour un saut de 2 classes à 70 000 € HT pour un saut de 4 classes ou plus).',
        montants: {
          aise: {
            montant: '10, 15 ou 20\xa0%'
          },
          inter: {
            montant: '45 ou 50\xa0%'
          },
          modeste: {
            montant: '60\xa0%'
          },
          tresModeste: {
            montant: '80\xa0%'
          }
        },
        modalButton: 'Voir les montants et plafonds'
      },
      block2: {
        title: 'Les aides par geste de travaux',
        intro: 'Ces aides financent des <b>gestes de travaux d\'isolation, de chauffage et d\'eau chaude sanitaire.</b> Il est possible de réaliser un ou plusieurs gestes (simultanément ou échelonnés dans le temps).',
        condition: 'Respecter les exigences de performance définies par geste de travaux',
        link: 'Voir les exigences pour chaque travaux',
        warning: 'Les logements classés F ou G ne seront <b>plus éligibles à partir de 2026</b>.',
        montantForfait: 'Le montant des aides est <b>forfaitaire et dépend du niveau de revenus du ménage.</b>',
        modalButton: 'Voir les montants forfaitaires'
      },
      block4: {
        title: 'Rappel des conditions d’accès aux aides MaPrimeRénov’',
        check1: '<b>Tous les propriétaires occupants et bailleurs peuvent prétendre aux aides MaPrimeRénov’</b>',
        warning1: '<b>Les personnes morales</b> comme les SCI et les nus-propriétaires <b>ne sont pas éligibles.</b>',
        check2: '<b>Le logement doit être occupé en tant que résidence principale</b> (soit plus de 8 mois dans l’année)',
        check3: '<b>Le logement doit être achevé depuis plus de 15 ans.</b>',
        info: '<b>Le remplacement de chaudière fioul et la dépose de cuve fioul</b> bénéficient d’une exception et <b>sont éligibles pour les logements de plus de 2 ans.',
        check4: '<b>Les travaux doivent être réalisés par un professionnel RGE</b> ou disposant de la qualification par chantier',
        check5: '<b>Les travaux doivent être réalisés dans un délai maximal de 2 ans à partir de la date d\'acceptation de la demande de prime</b> (ou dans un délai de 1 an à partir de la date d’attribution d’une demande d’avance).</b>'
      }
    },
    lesson2: {
      tab: 'cumul',
      title: 'Règles de cumul des aides MaPrimeRénov’',
      subTitle: 'pour les logements individuels',
      intro: 'Selon le type d’aide MaPrimeRénov’, les règles de cumul avec les CEE sont différentes.',
      block1: {
        title: 'Aide à la rénovation d\'ampleur',
        title2: 'aides par geste de travaux',
        conditions: {
          cond1: '<b>Les CEE sont valorisés par l’ANAH</b> : pas de dossier supplémentaire à déposer',
          cond2: '<b>Cumulable avec les aides locales</b> dans la limite des dépenses éligibles'
        },
        conditions2: {
          cond1: '<b>Cumulable avec les CEE</b>',
          cond2: '<b>Cumulable avec les aides locales</b> dans la limite des dépenses éligibles'
        }
      },
      block2: {
        title: 'Montant maximal d’aides par geste de travaux',
        subTitle: 'Plafond par logement',
        content: '<b>Pour un même logement,</b> le montant maximum d’aides MaPrimeRénov’ est fixé à <b>20&nbsp;000&nbsp;€ par période de 5 ans.</b>',
        subTitle2: 'Plafond par intervention ou la règle d’écrêtement',
        content2: '<b>Le montant cumulé de MaPrimeRénov’ et des aides CEE ne peut pas dépasser une part des dépenses éligibles.</b> Cette part dépend du niveau de revenus des bénéficiaires&nbsp;:',
        content3: '<b>Si le montant cumulé des aides dépasse le plafond maximal, MaPrimeRénov’ sera écrêtée</b>, c’est-a-dire que son montant sera réduit pour respecter le plafond.',
        montants: {
          tresModeste: '90\xa0%\xa0max',
          modeste: '75\xa0%\xa0max',
          intermediaire: '60\xa0%\xa0max'
        }
      }
    },
    lesson3: {
      tab: 'copropriétés',
      title: 'Zoom sur MaPrimeRénov’ Copropriété',
      block1: {
        content: 'MaPrimeRénov’ Copropriété permet de financer des travaux de rénovation énergétique sur les parties communes : murs, menuiseries, ventilation, systèmes de chauffage ou d’eau chaude collectif…'
      },
      block2: {
        title: 'règles d’éligibilité',
        content: 'Pour être éligible à l’aide, une copropriété doit\xa0:',
        check1: {
          title1: 'copropriété d’au moins 21 lots',
          content1: 'Avoir au moins 75% des lots en résidence principale',
          title2: 'copropriété de moins de 21 lots',
          content2: 'Avoir au moins 65% des lots en résidence principale'
        },
        check2: {
          content1: 'Réaliser des travaux permettant un gain énergétique d’au moins 35%',
          title2: 'copropriété de moins de 21 lots',
          content2: 'Réaliser des travaux permettant un gain énergétique d’au moins 15%'
        },
        check3: '<b>Être accompagnée d’un Assistant à Maîtrise d’ouvrage</b>',
        check4: '<b>Être inscrite au répertoire national des copropriétés</b>',
        check5: '<b>Ne pas installer de chaudière gaz</b>'
      },
      block3: {
        title: 'montant de l’aide',
        intro: 'Le montant d’aide délivré dépend du gain énergétique\xa0:',
        montant1: {
          title: '35 à 50 % de gain énergétique',
          montant: '30 % du coût HT des travaux',
          annotation: 'plafonné à 25 000 € par logement'
        },
        montant2: {
          title: '+ de 50 % de gain énergétique',
          montant: '45 % du coût HT des travaux',
          annotation: 'plafonné à 25 000 € par logement'
        },
        section2: {
          intro: 'Le montant de cette prime peut être bonifié dans les cas suivants\xa0:',
          item1Content: 'Bonus « Sortie de passoire » avec atteinte de la classe D',
          item1Annotation: '+ 10 % de prise en charge',
          item2Content: 'Prime « Copropriétaire occupant aux revenus modestes »',
          item2Annotation: '+ 1 500 € par logement concerné',
          item3Content: 'Prime « Copropriétaire occupant aux revenus très modestes »',
          item3Annotation: '+ 3 000 € par logement concerné'
        },
        section3: {
          title: 'Cumul avec d’autres aides',
          content: 'L’aide MaPrimeRénov’ Copropriété est cumulable avec des aides CEE ou d’autres aides publiques.',
          contentBold: 'Le cumul des aides ne doit pas dépasser 80% du montant TTC du coût des travaux.'
        },
        section4: {
          example1: {
            title: 'Exemple 1',
            item1: {
              title: 'Copropriété de <b>100 lots</b>',
              annotation: 'Éligible car plus de 75 % des lots sont occupés en résidence principale'
            },
            item2: {
              title: '<b>Gain énergétique</b> : 38 %',
              annotation: 'Classe E à C ➜ pas de bonus « Sortie de passoire »'
            },
            item3: {
              title: '<b>Coût des travaux</b> : 1 180 000 € HT',
              annotation: 'Inférieur au plafond de dépenses éligibles de 2 500 000 €'
            },
            item4: {
              title: '<b>10 propriétaires occupants « Très modestes »</b>',
              title2: '<b>20 propriétaires occupants « Modestes »</b>'
            },
            item5: {
              title: '<b>Montant d’aides</b> : 414 000 €',
              annotation1: {
                title: '<b>Montant de base : 354 000 €</b>',
                annotation: 'Gain ≤ 50 % ➜ 30% des dépenses éligibles'
              },
              annotation2: {
                title: '<b>Primes « Modeste » : 30 000 €</b>',
                annotation: '(1 500 € x 20 foyers concernés)'
              },
              annotation3: {
                title: '<b>Primes « Très modeste » : 30 000 €</b>',
                annotation: '(3 000 € x 10 foyers concernés)'
              }
            }
          },
          example2: {
            title: 'Exemple 2',
            item1: {
              title: 'Copropriété de <b>18 lots</b>',
              annotation: 'Éligible car plus de 65 % des lots sont occupés en résidence principale'
            },
            item2: {
              title: '<b>Gain énergétique :</b> 53 %',
              annotation: 'Classe G à C ➜ bonus « Sortie de passoire »'
            },
            item3: {
              title: '<b>Coût des travaux :</b> 775 000 € HT',
              annotation: 'Supérieur au plafond de dépenses éligibles de 450 000 €'
            },
            item4: {
              title: '<b>6 propriétaires occupants « Très modestes »</b>',
              title2: '<b>2 propriétaires occupants « Modestes »</b>'
            },
            item5: {
              title: '<b>Montant d’aides</b> : 268 500 €',
              annotation1: {
                title: '<b>Montant de base : 202 500 €</b>',
                annotation: 'Gain > 50 % ➜ 45% des dépenses éligibles'
              },
              annotation2: {
                title: '<b>Bonus Sortie de passoire : 45 000 €</b>',
                annotation: '10% des dépenses éligibles'
              },
              annotation3: {
                title: '<b>Primes « Modeste » : 3 000 €</b>',
                annotation: '(1 500 € x 2 foyers concernés)'
              },
              annotation4: {
                title: '<b>Primes « Très modeste » : 18 000 €</b>',
                annotation: '(3 000 € x 6 foyers concernés)'
              }
            }
          }
        }
      }
    }
  },
  part3: {
    intro: {
      objectif1: 'Distinguer les différentes étapes de la démarche MaPrimeRénov’',
      objectif2: 'Identifier les actions et le suivi à réaliser après une demande MaPrimeRenov’ ',
      objectif3: 'Appréhender les moyens de suivi et de contrôles'
    },
    lesson1: {
      tab: 'étapes',
      title: 'Les étapes d’une demande MaPrimeRénov’',
      block1: {
        label: 'pré-requis',
        title: 'Avant de déposer une demande',
        content: 'Les propriétaires occupants ou bailleurs doivent\xa0:',
        check1: '<b>Avoir un devis établi par un professionnel RGE</b>',
        check2: '<b>Connaître le montant des primes CEE et Coup de pouce</b> si applicable',
        warning: '<b>La demande de prime doit impérativement avoir lieu avant le début des travaux.</b> Seules les primes aux prestations Mon Accompagnateur Rénov’ et AMO peuvent être demandées après avoir bénéficié de la prestation.'
      },
      subtitle1: 'Vue globale des étape',
      vueGlobale: {
        step1Name: '<b>Création d\'un compte</b>',
        step2Name: '<b>Dépôt de la demande</b>',
        step3Name: '<b>Réponse de l\'ANAH</b>',
        step4Name: '<b>Réalisation des travaux</b>',
        step5Name: '<b>Demande de paiement</b>',
        step6Name: '<b>Contrôle de la demande</b>',
        step7Name: '<b>Versement de la prime</b>'
      },
      subtitle2: 'Détail des étapes',
      step1: {
        label: 'étape 1',
        title: 'création d’un compte maprimerénov’',
        content: 'Pour permettre le dépôt de la demande, <b>le bénéficiaire doit créer son compte sur le <a class="link-internal" href="https://www.maprimerenov.gouv.fr" target="_blank" rel="noopener">site internet officiel de MaPrimeRénov’</a>.</b><br>' +
          '<br>' +
          'Une fois connecté, le bénéficiaire peut <b>nommer un mandataire administratif, financier ou mixte.</b>',
        drawerHead1: 'Informations requises pour les propriétaires',
        drawerContent1: 'Les <b>informations d’état civil</b> ainsi que celles des membres du foyer et le <b>dernier avis d’imposition</b> (ainsi que ceux des membres du foyer le cas échéant).',
        drawerHead2: 'Informations requises pour les copropriétés',
        drawerContent2: '<b>Le numéro d’immatriculation de la copropriété et l’Assistant à Maîtrise d’Ouvrage</b> qui se chargera de réaliser une étude du bâtiment afin de définir les travaux pertinents et établir qu’ils permettent un gain de 35% d’économies d’énergie.'
      },
      step2: {
        label: 'étape 2',
        title: 'dépôt de la demande',
        content: 'Une demande peut contenir plusieurs gestes de travaux de rénovation. En revanche, <b>il est impossible d’ajouter un geste de travaux une fois la demande déposée.</b>',
        drawerHead: 'Documents à fournir au dépôt de la demande',
        drawerContent: '- Le devis signé pour chaque travaux<br>' +
          '- Le montant des primes CEE pour chaque travaux et les documents en attestant',
        drawerContentWarning: 'Pour les demandes d’aides par geste, le DPE ou l’audit réglementaire du logement',
        drawerContent2: '<b>Pour les propriétaires bailleurs :</b><br>' +
          '- Un justificatif de propriété<br>' +
          '- L’attestation signée d’engagement à louer leur bien en tant que résidence principale pour une durée d’au moins 5 ans et dans un délai d’un an suivant la demande.',
        drawerContent3: 'Les acquéreurs d\'un logement peuvent déposer une demande sur leur future nouvelle adresse à condition d\'emménager sous moins d\'un an.',
        info: '<b>Les ménages modestes et très modestes peuvent également faire une demande d’avance</b> dans la limite de 70% du montant de la prime.'
      },
      step3: {
        label: 'étape 3',
        title: 'réponse de l’anah',
        content: 'Une fois que l’ANAH a étudié la demande, <b>elle notifie le bénéficiaire et le mandataire par email et donne un montant prévisionnel de la prime.</b>'
      },
      step4: {
        label: 'étape 4',
        title: 'réalisation des travaux',
        content: 'Les travaux ne peuvent commencer qu’une fois la demande validée par l’ANAH.',
        info: '<b>Si les travaux sont urgents</b> (risque pour la santé ou la sécurité) ou pour tout <b>remplacement d’une chaudière fioul en panne, les travaux peuvent demander une dérogation pour commencer les travaux avant l’acception</b> de la demande de prime par l’ANAH.'
      },
      step5: {
        label: 'étape 5',
        title: 'demande de paiement de la prime',
        content: 'À l’issue des travaux, la demande de prime peut être effectuée en fournissant à l’ANAH&nbsp;:<br>' +
          '- la facture<br>' +
          '- le RIB<br>' +
          '- les éventuels justificatifs de perception de primes CEE'
      },
      step6: {
        label: 'étape 6',
        title: 'contrôle de la demande',
        content: 'Une fois le dossier complet, <b>l’ANAH a 2 mois de délai règlementaire pour instruire le dossier.</b> En règle générale un dossier complet et conforme est instruit sous 15 jours ouvrés.',
        content2: 'L’ANAH réalise deux types de contrôles\xa0:',
        tab1: {
          title: 'Contrôle sur pièces',
          content: '- <b>Systématique</b><br>' +
            '- Assuré par <b>les instructeurs de l’ANAH</b>',
          info: 'Les instructeurs peuvent être amenés à <b>contacter le ménage, le mandataire ou encore l’entreprise qui a réalisé les travaux.</b>'
        },
        tab2: {
          title: 'Contrôle sur site',
          content: '- <b>Aléatoire</b><br>' +
            '- Assuré par un <b>organisme agréé <abbr title="Comité français d\'accréditation">COFRAC</abbr></b><br>' +
            '- Peut intervenir <b>avant ou après le versement de la prime</b>'
        }
      },
      step7: {
        label: 'étape 7',
        title: 'versement de la prime',
        content: 'Si le dossier est validé, <b>l’ANAH verse la prime au bénéficiaire ou directement au mandataire financier.</b>'
      }
    },
    lesson2: {
      tab: 'conseils',
      titleAnnotation: 'En plus d’être garant de la qualité des travaux, <b>le professionnel du bâtiment doit s’assurer que les documents qu’il édite et fournit sont conformes</b> (devis, factures, justificatifs de performance énergétique) pour que la prime puisse être versée sans délai.',
      block1: {
        title: 'Quelques conseils pratiques pour l’édition des documents',
        steps: {
          step1: {
            label: 'destinataire',
            content: '<b>Adresser l’ensemble des pièces</b> (devis, factures) <b>au nom et prénom du bénéficiaire</b> et non à ceux d’un autre membre du ménage'
          },
          step2: {
            label: 'performance',
            content: '<b>Faire apparaître précisément les critères de performance énergétique des équipements ou des matériels</b> pour chaque geste de travaux faisant l’objet d’une demande'
          },
          step3: {
            label: 'opération cee',
            content: '<b>Présenter les aides CEE sur le devis avec la mention exacte</b> indiquée dans le cadre contribution'
          },
          step4: {
            label: 'reste à charge',
            content: '<b>Distinguer clairement le montant total TTC des travaux du montant restant à la charge du client</b> une fois les primes CEE déduites'
          }
        }
      },
      endBlock: 'Voyons si vous avez bien assimilé les spécificités de MaPrimeRénov’'
    },
    activity: {
      title: 'Mettez en relation les acteurs en fonction du contexte',
      intro: {
        title: 'Identifier les règles et étapes à respecter lors d’une demande MaPrimeRénov’',
        rules: '<b>Accompagnez un propriétaire bailleur</b> d’une maison louée en résidence principale dans ses démarches <b>en l’orientant vers le bon interlocuteur.</b>'
      },
      questions: {
        question1: {
          context: 'demande de devis',
          label: 'Le propriétaire veut rénover son bien pour valoriser son patrimoine.',
          question: 'Qui devrait-il consulter\xa0?',
          answers: {
            answer1: 'Conseiller France Rénov’',
            answer2: 'Accompagnateur Rénov’'
          },
          correction: '<b>Il pourra obtenir des conseils gratuits et indépendants auprès d’un conseiller France Rénov’.</b> Celui-ci pourra l’orienter dans son projet de travaux et le mettre en relation avec un accompagnateur Rénov’ uniquement si le propriétaire a des revenus modestes et envisage une rénovation globale.'
        },
        question2: {
          context: 'calcul du montant',
          label: 'Le propriétaire a fait établir des devis pour ses travaux. Il souhaite maintenant savoir à combien d’aides il a droit.',
          question: 'Qui peut estimer le montant total d’aides pour les travaux\xa0?',
          answers: {
            answer1: 'ANAH',
            answer2: 'Conseiller France Rénov’'
          },
          correction: '<b>Le Conseiller France Rénov’ peut estimer les montants prévisionnels des aides MaPrimeRénov’, CEE mais aussi des aides locales éligibles.</b><br>' +
            'L’ANAH n’estimera que le montant de MaPrimeRénov’ lors du dépôt de la demande d’aides.'
        },
        question3: {
          context: 'parcours accompagné',
          label: 'Les travaux envisagés vont améliorer la performance énergétique.',
          question: 'Qui le ménage doit-il contacter pour établir que les travaux sont éligibles à MaPrimeRénov’ Parcours Accompagné\xa0?',
          answers: {
            answer1: 'Mon Accompagnateur Rénov’',
            answer2: 'Mandataire'
          },
          correction: 'Le recours à <b>Mon Accompagnateur Rénov’ est obligatoire pour accéder à l’aide « MaPrimeRénov’ Parcours accompagné »</b>, celui-ci sera chargé de réaliser l’audit énergétique et aider à définir les travaux respectant les conditions d’obtention de la prime.<br>' +
            'Le mandataire ne réalise pas d’études techniques.'
        },
        question4: {
          context: 'montage du dossier',
          label: 'Le propriétaire a créé son compte sur maprimerenov.gouv.fr et doit maintenant déposer sa demande.',
          question: 'Qui peut l’aider pour monter et suivre son dossier\xa0?',
          answers: {
            answer1: 'Assistant à Maîtrise d\'Ouvrage',
            answer2: 'Mandataire'
          },
          correction: '<b>Le rôle de l’Assistant à Maîtrise d\'Ouvrage est d’aider les maîtres d’ouvrage dans la définition des travaux et éventuellement le suivi du chantier.</b><br>' +
            'Il pourra éventuellement endosser le rôle de mandataire administratif à condition d’avoir un compte mandataire et d’être désigné par le bénéficiaire de MaPrimeRénov’.'
        },
        question5: {
          context: 'contrôle du dossier',
          label: 'L’ANAH a reçu le dossier complet et procède au contrôle des pièces. Elle doit vérifier des informations…',
          question: 'Qui contacte-t-elle\xa0?',
          answers: {
            answer1: 'Assistant à Maîtrise d\'Ouvrage',
            answer2: 'Bénéficiaire / maître d\'ouvrage'
          },
          correction: '<b>L’ANAH contacte principalement le bénéficiaire,</b> notamment lorsque la demande a été déposée par un mandataire, l’ANAH vérifie que le bénéficiaire en est bien à l’origine. Elle vérifie par ailleurs la validité de la qualification RGE correspondant aux travaux et le numéro d’immatriculation du mandataire.'
        },
        question6: {
          context: 'paiement de la prime',
          label: 'Le dossier est validé ! Sachant que le propriétaire a désigné un mandataire mixte…',
          question: 'À qui l’ANAH reverse-t-elle la prime\xa0?',
          answers: {
            answer1: 'Bénéficiaire',
            answer2: 'Mandataire'
          },
          correction: 'Le propriétaire ayant fait appel à un mandataire mixte, c’est-à-dire administratif et financier, le mandataire recevra directement la prime de l’ANAH.'
        }
      }
    }
  }
}

export default module3Messages
