<script>
export default {
  name: 'Example2'
}
</script>

<template>
  <div class="copro-example-section">
    <p class="example-title">{{ $t('module3.part2.lesson3.block3.section4.example2.title') }}</p>
    <div class="conditions-block">
      <div class="conditions">
        <div class="condition">
          <img :src="require('@/assets/module1/part2/lesson/logement-ico.svg')">
          <div>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item1.title')"></p>
            <p class="annotation">{{ $t('module3.part2.lesson3.block3.section4.example2.item1.annotation') }}</p>
          </div>
        </div>
        <div class="condition">
          <img :src="require('@/assets/module3/part2/ico-gain-classe.svg')">
          <div>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item2.title')"></p>
            <p class="annotation">{{ $t('module3.part2.lesson3.block3.section4.example2.item2.annotation') }}</p>
          </div>
        </div>
        <div class="condition">
          <img :src="require('@/assets/module1/part2/lesson/outils-ico.svg')">
          <div>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item3.title')"></p>
            <p class="annotation">{{ $t('module3.part2.lesson3.block3.section4.example2.item3.annotation') }}</p>
          </div>
        </div>
        <div class="condition">
          <img :src="require('@/assets/module1/part2/lesson/qui-ico.svg')">
          <div>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item4.title')"></p>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item4.title2')"></p>
          </div>
        </div>
        <div class="condition">
          <img :src="require('@/assets/module1/part3/illu-money.svg')">
          <div>
            <p class="condition-title" v-html="$t('module3.part2.lesson3.block3.section4.example2.item5.title')"></p>
            <div class="annotation">
              <div>
                <p v-html="$t('module3.part2.lesson3.block3.section4.example2.item5.annotation1.title')"></p>
                <p>{{ $t('module3.part2.lesson3.block3.section4.example2.item5.annotation1.annotation') }}</p>
              </div>
              <div>
                <p v-html="$t('module3.part2.lesson3.block3.section4.example2.item5.annotation2.title')"></p>
                <p>{{ $t('module3.part2.lesson3.block3.section4.example2.item5.annotation2.annotation') }}</p>
              </div>
              <div>
                <p v-html="$t('module3.part2.lesson3.block3.section4.example2.item5.annotation3.title')"></p>
                <p>{{ $t('module3.part2.lesson3.block3.section4.example2.item5.annotation3.annotation') }}</p>
              </div>
              <div>
                <p v-html="$t('module3.part2.lesson3.block3.section4.example2.item5.annotation4.title')"></p>
                <p>{{ $t('module3.part2.lesson3.block3.section4.example2.item5.annotation4.annotation') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../../../styles/module3Lesson";
</style>
