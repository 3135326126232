<template>
  <div class="fiche-aide-fi app-block">
    <div class="app-block-title">{{ aide.label }}</div>
    <div class="app-block-content">
      <div class="head">
        <div class="logo-wrapper">
          <img :src="aide.logo">
        </div>

        <div class="section">
          <p class="section-content" v-html="aide.intro"></p>
        </div>
      </div>

      <section-fiche-aide v-if="aide.logement !== undefined"
        :icon="require('@/assets/module1/part2/lesson/logement-ico.svg')"
        :label="$t('module1.part2.subTitles.logement')"
      >
        <template #default>
          <slot name="logement">
            <p v-html="aide.logement"></p>
          </slot>
        </template>
      </section-fiche-aide>

      <section-fiche-aide v-if="aide.qui !== undefined"
        :icon="require('@/assets/module1/part2/lesson/qui-ico.svg')"
        :label="$t('module1.part2.subTitles.qui')"
      >
        <template #default>
          <slot name="qui">
            <p v-html="aide.qui"></p>
          </slot>
        </template>
      </section-fiche-aide>

      <section-fiche-aide v-if="aide.montant !== undefined"
        :icon="require('@/assets/money-ico.svg')"
        :label="$t('module1.part2.subTitles.montant')"
      >
        <template #default>
          <slot name="montant" :montant="aide.montant">
            <p v-html="aide.montant"></p>
          </slot>
        </template>
      </section-fiche-aide>

      <section-fiche-aide v-if="aide.travaux !== undefined"
        :icon="require('@/assets/module1/part2/lesson/outils-ico.svg')"
        :label="$t('module1.part2.subTitles.travaux')"
      >
        <template #default>
          <slot name="travaux">
            <p v-html="aide.travaux.intro"></p>
            <section-fiche-travaux
              :travaux="aide.travaux.items"
              :reno-ampleur-annotation="aide.travaux.renoAmpleurAnnotation"
            />
          </slot>
        </template>
      </section-fiche-aide>

      <section-fiche-aide v-if="aide.obtention !== undefined"
        :icon="require('@/assets/module1/part2/lesson/obtention-ico.svg')"
        :label="$t('module1.part2.subTitles.obtention')"
      >
        <template #default>
          <slot name="obtention">
            <div class="checklist">
              <div
                class="item"
                v-for="item in aide.obtention"
                :key="item" v-html="item"
              >
              </div>
            </div>
          </slot>
        </template>
      </section-fiche-aide>
    </div>
  </div>
</template>

<script>
import SectionFicheAide from '@/components/training/module1/part2/SectionFicheAide.vue'
import SectionFicheTravaux from '@/components/training/module1/part2/SectionFicheTravaux.vue'

export default {
  name: 'AppBlockFicheAideFi',
  components: { SectionFicheTravaux, SectionFicheAide },
  props: {
    aide: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.fiche-aide-fi {
  .app-block-content {
    display: flex;
    flex-direction: column;
    gap: $space-xl;
    align-items: center;
  }
  .head {
    display: flex;
    flex-direction: column;
    gap: $space-m;
    justify-content: center;
    text-align: center;
  }
  .logo-wrapper {
    margin: 0 auto;
    width: 180px;
    display: flex;
    justify-content: center;
    padding: $space-sm;
    align-items: center;
    border-radius: $radius;
    border: 1px solid $c-background;
    box-shadow: 0 2px 1px rgba(181, 186, 190, 0.4);
    img {
      max-width: 100%;
    }
  }
}

@media (min-width: $bp-tablet) {
  .fiche-aide-fi {
    .head {
      flex-direction: row;
      align-items: center;
      text-align: left;
    }
    .logo-wrapper {
      width: 280px;
    }
  }
}
</style>
